@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply box-border;
  }

   body {
    @apply bg-mf-dark w-full h-[100vh];
  }
}